import React from "react";
import { Link } from "gatsby";
import { motion } from "framer-motion";
import { AnchorLink } from "gatsby-plugin-anchor-links";

const Navbar = class extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      navBarActiveClass: "",
    };
  }

  render() {
    return (
      <div>
        <nav data-scroll data-scroll-sticky data-scroll-target="#container" className="desktopmenu">
          <div className="main-nav">
            <div className="logos">
              <Link to="/" className="logo" aria-label="homepage">
                <img src="/img/excel_logo_white.svg" alt="homepage" />
              </Link>
              <Link to="/" className="logo-mobile" aria-label="homepage">
                <img src="/img/favicon.svg" alt="homepage" />
              </Link>
            </div>
            <div className="navigation">
              <Link to="/">Home</Link>
              <Link to="/contact">Contact</Link>
            </div>
            <div className="navigation rightoyo">
              <a href="https://twitter.com/ExcelHelicopter" target="_blank">
                <img src="../img/twit.svg" />
              </a>
              <a
                href="https://www.facebook.com/ExcelHelicoptersUK/"
                target="_blank"
              >
                <img src="../img/face.svg" />
              </a>
              <a
                href="https://www.linkedin.com/company/excel-helicopter-charter-ltd/"
                target="_blank"
              >
                <img src="../img/li.svg" />
              </a>
              <a
                href="https://www.instagram.com/excelhelicopters/"
                target="_blank"
              >
                <img src="../img/ig.svg" />
              </a>
            </div>
          </div>
          <div className="subnav-indicator">
            <div className="indicator"></div>
          </div>
          <div className="subnav">
            <Link to="/charter">Charter & London Sightseeing</Link>
            <Link to="/operations">Special Operations</Link>
            <Link to="/maintenance">Maintenance & Engineering</Link>
            <Link to="/fleet">Our Fleet</Link>
          </div>
        </nav>
        <div className="mobilemenu">
        <Link to="/" className="logo-mobile" aria-label="homepage">
                <img src="/img/mobile-logo.svg" alt="homepage" />
              </Link>
			    <input className="burger" type="checkbox" />
			    <nav>
            <Link to="/charter">Charter</Link>
            <Link to="/operations">Special Operations</Link>
            <Link to="/maintenance">Maintenance & Engineering</Link>
            <Link to="/fleet">Our Fleet</Link>
            <Link to="/contact">Contact</Link>
          </nav>
        </div>
      </div>
    );
  }
};

export default Navbar;
