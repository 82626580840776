import React, { useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import Footer from "../components/Footer";
import Navbar from "../components/Navbar";
import "./all.css";
import useSiteMetadata from "./SiteMetadata";
import { withPrefix } from "gatsby";
import DotRing from "../components/DotRing/DotRing";
import { motion } from "framer-motion";
import PropTypes from "prop-types"

import Scroll from "../components/locomotiveScroll";
import "../components/locomotive-scroll.css";



const TemplateWrapper = ({ children, location }) => {
  const { title, description } = useSiteMetadata();


  return (
    <div>
      <Helmet>
        <html lang="en" />
        <title>{title}</title>
        <meta name="description" content={description} />

        <link
          rel="apple-touch-icon"
          sizes="180x180"
          href={`${withPrefix("/")}img/favicon.svg`}
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon.svg`}
          sizes="32x32"
        />
        <link
          rel="icon"
          type="image/png"
          href={`${withPrefix("/")}img/favicon.svg`}
          sizes="16x16"
        />

        <link
          rel="mask-icon"
          href={`${withPrefix("/")}img/favicon.svg`}
          color="#cbfc02"
        />
        <meta name="theme-color" content="#cbfc02" />

        <meta property="og:type" content="business.business" />
        <meta property="og:title" content={title} />
        <meta property="og:url" content="/" />
        <meta
          property="og:image"
          content={`${withPrefix("/")}img/og-image.jpg`}
        />
      </Helmet>


      {/* Here we pass the callbacks to the component. Anything that impacts the innerHeight, for example: Font Loaded */}
      <Scroll callbacks={location} />

      <div id="container">
      <Navbar />
      <motion.main
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0,
          }}
          transition={{
            type: "spring",
            mass: 0.35,
            stiffness: 75,
            duration: 1
          }}
        >
                
          {children}
          <Footer />
        </motion.main>
        
      </div>
    </div>
  );
};

TemplateWrapper.propTypes = {
  children: PropTypes.node.isRequired
}

export default TemplateWrapper;
