import React from "react";
import { Link } from "gatsby";

const Footer = class extends React.Component {
  render() {
    return (
      <section className="footy">
        <div className="footer">
          <div className="footer-container">
            <div className="grid2">
              <div className="item-left">
                <div className="address">
                Hangar 17, Stapleford Aerodrome,<br />
                            Stapleford Tawney, Essex, RM4 1SJ
                </div>
                <div className="copyright">
                
                  © 2021 Excel Helipcopters. All rights reserved.
                </div>
              </div>
              <div className="item-right">
                <ul>
                  <li>
                    <a href="tel:0800 368 8975" target="_blank">0800 368 8975</a>
                  </li>
                  <li>
                    <a href="mailto:contact@excelhelicopters.co.uk" target="_blank">contact@excelhelicopters.co.uk</a>
                  </li>
                  
                  <li className="highlight">
                    <a className="highlightin" href="https://wearefuture.agency" target="_blank">Site by Future</a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
  
};

export default Footer;
